<template>
	<div>
		<Navbar/>
		<div :style="{'background-image': `url(${require('../assets/img/cover7.jpg')})`, 'background-size': `cover`, 'background-position': 'center'}" class="pt-12">
			<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8" >
				<div class="lg:text-center mb-8">
					<p class="text-base leading-6 text-hydro font-semibold tracking-wide uppercase"> {{ $t('web.domain') }}</p>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						{{ $t('domains.headline') }}
					</h3>
					<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-700 lg:mx-auto">
						{{ $t('domains.headline2') }}
					</p>
				</div>
			</div>
		</div>
		<div class="bg-white">
			<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:items-center lg:justify-between">
				<div class="lg:text-center">
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						{{ $t('domains.offer') }}
					</h3>
					<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
						{{ $t('domains.offer_desc') }}
					</p>
					<form class="w-full">
						<form action="https://manager.hydrosaas.com/cart.php?a=add&domain=register" method="post" class="flex m-auto pt-4 max-w-md items-center border-b border-b-2 border-hydro py-2">
							<input name="query" class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="example.com" aria-label="domain name">
							<button type="submit" class="flex-shrink-0 bg-hydro hover:bg-hydroactive border-hydro hover:border-hydroactive text-sm border-4 text-white py-1 px-2 rounded">{{ $t('home.purchase') }}</button>
						</form>
					</form>
				</div>
			</div>
		</div>
		<div class="mx-5 my-8">
			<ul class="md:grid md:grid-cols-3 md:col-gap-8 md:row-gap-10">
				<li>
					<div class="flex-shrink-0">
						<img src="../assets/img/forward.svg" class="m-auto h-32" alt="chat">
					</div>
					<div class="ml-4 text-center">
						<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('domains.transfert') }}</h5>
						<p class="mt-2 text-base leading-6 text-gray-500">
							{{ $t('domains.transfert_desc') }}
						</p>
					</div>
				</li>
				<li>
					<div class="flex-shrink-0">
						<img src="../assets/img/security.svg" class="m-auto h-32" alt="chat">
					</div>
					<div class="ml-4 text-center">
						<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('domains.identity') }}</h5>
						<p class="mt-2 text-base leading-6 text-gray-500">
							{{ $t('domains.identity_desc') }}
						</p>
					</div>
				</li>
				<li>
					<div class="flex-shrink-0">
						<img src="../assets/img/chat.svg" class="m-auto h-32" alt="chat">
					</div>
					<div class="ml-4 text-center">
						<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('domains.identity') }}</h5>
						<p class="mt-2 text-base leading-6 text-gray-500">
							{{ $t('domains.identity_desc') }}
						</p>
					</div>
				</li>
			</ul>
		</div>

		<div class="bg-gray-100">
			<div class="md:px-32 py-8 w-full">
				<div class="shadow overflow-hidden rounded border-b border-gray-200">
					<table class="min-w-full bg-white">
						<thead class="bg-gray-800 text-white">
						<tr>
							<th class="w-1/3 text-left py-3 px-4 uppercase font-semibold text-sm">{{ $t('domains.ext') }}</th>
							<th class="w-1/3 text-left py-3 px-4 uppercase font-semibold text-sm">{{ $t('domains.register') }}</th>
							<th class="text-left py-3 px-4 uppercase font-semibold text-sm">{{ $t('domains.trans') }}</th>
							<th class="text-left py-3 px-4 uppercase font-semibold text-sm">{{ $t('domains.renewal') }}</th>
						</tr>
						</thead>
						<tbody class="text-gray-700">
						<tr>
							<td class="w-1/3 text-left py-3 px-4">.com</td>
							<td class="w-1/3 text-left py-3 px-4">9,99€</td>
							<td class="text-left py-3 px-4">9,99€</td>
							<td class="text-left py-3 px-4">12,99€</td>
						</tr>
						<tr>
							<td class="w-1/3 text-left py-3 px-4">.fr</td>
							<td class="w-1/3 text-left py-3 px-4">10,99€</td>
							<td class="text-left py-3 px-4">10,99€</td>
							<td class="text-left py-3 px-4">12,99€</td>
						</tr>
						<tr>
							<td class="w-1/3 text-left py-3 px-4">.eu</td>
							<td class="w-1/3 text-left py-3 px-4">6,99€</td>
							<td class="text-left py-3 px-4">6,99€</td>
							<td class="text-left py-3 px-4">9,99€</td>
						</tr>
						<tr>
							<td class="w-1/3 text-left py-3 px-4">.net</td>
							<td class="w-1/3 text-left py-3 px-4">9,99€</td>
							<td class="text-left py-3 px-4">9,99€</td>
							<td class="text-left py-3 px-4">12,99€</td>
						</tr>
						<tr>
							<td class="w-1/3 text-left py-3 px-4">.be</td>
							<td class="w-1/3 text-left py-3 px-4">6,99€</td>
							<td class="text-left py-3 px-4">6,99€</td>
							<td class="text-left py-3 px-4">9,99€</td>
						</tr>
						<tr>
							<td class="w-1/3 text-left py-3 px-4">.info</td>
							<td class="w-1/3 text-left py-3 px-4">15,99€</td>
							<td class="text-left py-3 px-4">15,99€</td>
							<td class="text-left py-3 px-4">18,99€</td>
						</tr>
						<tr>
							<td class="w-1/3 text-left py-3 px-4">.ch</td>
							<td class="w-1/3 text-left py-3 px-4">9,99€</td>
							<td class="text-left py-3 px-4">9,99€</td>
							<td class="text-left py-3 px-4">12,99€</td>
						</tr>
						<tr>
							<td class="w-1/3 text-left py-3 px-4">.pro</td>
							<td class="w-1/3 text-left py-3 px-4">14,99€</td>
							<td class="text-left py-3 px-4">14,99€</td>
							<td class="text-left py-3 px-4">17,99€</td>
						</tr>
						<tr>
							<td class="w-1/3 text-left py-3 px-4">.xyz</td>
							<td class="w-1/3 text-left py-3 px-4">12,99€</td>
							<td class="text-left py-3 px-4">12,99€</td>
							<td class="text-left py-3 px-4">15,99€</td>
						</tr>
						<tr>
							<td class="w-1/3 text-left py-3 px-4">.org</td>
							<td class="w-1/3 text-left py-3 px-4">12,99€</td>
							<td class="text-left py-3 px-4">12,99€</td>
							<td class="text-left py-3 px-4">15,99€</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div class="bg-gray-50">
			<Footer />
		</div>
	</div>
</template>

<script>
	import Navbar from "@/components/Navbar";
	import Footer from "@/components/Footer";
	export default {
		name: 'Domain',
      metaInfo: () => ({
        title: 'Noms de domaine HydroSaas',
        titleTemplate: '%s | Enregistrer un nom de domaine',
        meta: [
          { name: 'description', content: 'Choissez et enregistrez un nom de domaine pour votre site web, votre blog ou votre entreprise. Domaines pas cher : .fr .com .net .org .eu .site .be .ch .info .pro …' },
          { name: 'og:title', content: 'Enregistrer un nom de domaine sur HydroSaaS' },
          { name: 'twitter:title', content: 'Noms de domaines sur HydroSaaS' },
          { name: 'og:description', content: 'Choissez et enregistrez un nom de domaine pour votre site web, votre blog ou votre entreprise. Domaines pas cher : .fr .com .net .org .eu .site .be .ch .info .pro …' },
          { name: 'twitter:description', content: 'Choissez et enregistrez un nom de domaine pour votre site web, votre blog ou votre entreprise. Domaines pas cher : .fr .com .net .org .eu .site .be .ch .info .pro …' },
          { name: 'twitter:image', content: 'https://hydrosaas.com/default.png' },
          { name: 'og:image', content: 'https://hydrosaas.com/default.png' },
        ],
      }),
      htmlAttrs: {
        amp: true
      },
		components: {
			Footer,
			Navbar,
		},
	}
</script>
