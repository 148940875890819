<template>
  <div class="relative white bg-hydrodark overflow-hidden">
    <div class="ml-8 max-w-screen-xl mx-auto py-6  text-white">
        <p>© 2018 - {{new Date().getFullYear()}} | HydroChain SAS -  16 RUE SOLEILLET - 75020 PARIS | Tel : +33 9 86 06 56 48</p>
        <p>Siret : 841 795 792 00016 | TVA : FR51841795792 | {{ $t('footer.ht') }} | <router-link to="/mentions">{{ $t('footer.mentions') }}</router-link> - <router-link to="/about">{{ $t('footer.about') }}</router-link> - <router-link to="/tos">{{ $t('footer.tos') }}</router-link> - <a href="https://twitter.com/hydrosaas" target="_blank">Twitter</a> </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>
